import { FunctionComponent, useEffect, useLayoutEffect } from "react";
import { Navigate, Route, RouterProvider, Routes, useLocation, useNavigate } from 'react-router-dom';
import HomePage from "../pages/home/HomePage";
import OurSolutions from "../pages/OurSolutions/OurSolutions";
import SocialResponsibility from "../pages/SocialResponsibility/SocialResponsibility";
import Carreer from "../pages/Carreer/Carreer";
import AntiFraud from "../pages/AntiFraud/AntiFraud";
import About from "../pages/AboutPage/About";
import ContactUs from "../pages/ContactUs/ContactUs";
import Feedback from "../pages/Feedback/Feedback";
import ValidarBoleto from "../pages/ValidarBoleto/ValidarBoleto";

interface RoutesProps {

}

const Router: FunctionComponent<RoutesProps> = () => {

  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/sobre" element={<About />} />
      <Route path="/nossas-solucoes" element={<OurSolutions />} />s
      <Route path="/responsabilidade-social" element={<SocialResponsibility />} />
      <Route path="/carreira" element={<Carreer />} />
      <Route path="/recomendacoes-antifraude" element={<AntiFraud />} />
      <Route path="/fale-com-a-gente" element={<ContactUs />} />
      <Route path="/ouvidoria" element={<Feedback />} />
      <Route path="/validar-boleto" element={<ValidarBoleto />} />
      <Route path="*" element={<Navigate to="/" replace={true} />} />
    </Routes>
  );
}

export default Router;