export const toggleElementById = (elementId: string, toggle: boolean) => {
  const element = document.getElementById(elementId)

  if (toggle) {
    element?.classList.add('visible')
    element?.classList.remove('hidden')
  }
  else {
    element?.classList.add('hidden')
    element?.classList.remove('visible')
  }
}