import React, { createContext, useState, ReactNode, useContext } from "react";

interface CaptchaContextType {
  captchaToken: string;
  setCaptchaToken: (token: string) => void;
  resetCaptcha: () => void;
}

const CaptchaContext = createContext<CaptchaContextType | undefined>(undefined);

export const CaptchaProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [captchaToken, setCaptchaToken] = useState<string>("");

  const resetCaptcha = () => {
    setCaptchaToken("");
  };

  return (
    <CaptchaContext.Provider value={{ captchaToken, setCaptchaToken, resetCaptcha }}>
      {children}
    </CaptchaContext.Provider>
  );
};

export const useCaptcha = () => {
  const context = useContext(CaptchaContext)
  if (!context) {
    throw new Error("useCaptcha must be used within an AuthProvider")
  }
  return context
}
