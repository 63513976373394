import { FunctionComponent } from "react";
import TopBanner from "../../components/TopBanner/TopBanner";
import { CityChip, BpAttributesContainer, AssociateLawyersContainer, AttributesContent, AttributesContentContainer, BpBuildingsAddressesContainer, BuildingsAddresses, BuildingsImageContainer } from "./styles";
import { useTheme } from "styled-components";
import AboutBanner from "../../images/banner-sobre.png";
import MissionIcon from "../../images/icone-missao.png";
import VisionIcon from "../../images/icone-visao.png";
import ValuesIcon from "../../images/icone-valores.png";
import BuildingBp from "../../images/predio-bp.png";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface AboutProps {

}

const About: FunctionComponent<AboutProps> = () => {
  const theme = useTheme();

  const aboutContent = <>
    A <b>BP GESTÃO</b>, atua na recuperação de créditos com e sem garantia na cobrança amigável com atuação em modelos de negociação humana,
    digital e híbrido. A experiência e a tradição de mais de 20 anos fortalecem nossa eficiência e reconhecimento,
    permitindo-nos atuar junto às maiores instituições cedentes de crédito do país.

    <br /> <br />

    Contamos com uma equipe de profissionais altamente qualificados,
    compreendendo: negociadores, analistas, administradores, profissionais de tecnologia,
    cientistas de dados, peritos e muitos outros. Mais do que realizar negociações,
    buscamos oferecer a melhor experiência durante a negociação, garantindo os melhores resultados aos contratantes.

    <br /> <br />

    <b>Ao devedor, proporcionamos uma jornada humanizada com foco na melhor solução e o devido respeito à legislação pátria.</b>
  </>;

  const AssociateLawyers = (
    <AssociateLawyersContainer>
      <p className="role">Presidente</p>
      <p>Flaviano Bellinati Garcia Perez</p>

      <p className="role">Vice-Presidente</p>
      <p>Cristiane Bellinati Garcia Lopes</p>
    </AssociateLawyersContainer>
  )

  return (
    <>
      <TopBanner
        title="A Bellinati Perez"
        content={aboutContent}
        bottomContent={AssociateLawyers}
        shadowColor={theme.colors.grey.dark}
        bannerImg={AboutBanner}
        bgColor={theme.colors.white}
        bannerHeight={900}
        bannerHeightMid="970px"
      />

      <BpAttributesContainer>
        <AttributesContentContainer>
          <AttributesContent>
            <img src={MissionIcon} alt="Imagem de um alvo com uma flecha" />
            <div>
              <h4>
                Missão
              </h4>
              <p>Conquistar e manter a liderança na recuperação de ativos.</p>
            </div>
          </AttributesContent>
          <AttributesContent>
            <img src={VisionIcon} alt="Imagem de um alvo com uma flecha" />
            <div>
              <h4>
                Visão
              </h4>
              <p>Obter os melhores resultados para nossos parceiros e para nós em qualquer âmbito ou produto nos quais atuemos.</p>
            </div>
          </AttributesContent>
          <AttributesContent>
            <img src={ValuesIcon} alt="Imagem de um alvo com uma flecha" />
            <div>
              <h4>
                Valores
              </h4>
              <p>Trabalhar dentro dos princípios da ética, da humanização nos
                relacionamentos; do respeito aos clientes e fornecedores; da
                valorização das equipes, e, principalmente, no cumprimento às
                leis, sendo estes os pilares que formam a base sólida de nossos valores.</p>
            </div>
          </AttributesContent>
        </AttributesContentContainer>
      </BpAttributesContainer>

      <BpBuildingsAddressesContainer container>

        <BuildingsAddresses item xs={12} sm={12} md={12} lg={7} columnSpacing={2}>
          <div className="title-container">
            <h3>
              Estrutura
            </h3>
            <p>
              Estamos presentes em 6 filiais, porém atuamos em todo o território nacional, confira os nossos endereços.
            </p>
          </div>

          <Grid container rowSpacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CityChip className="chip-mb">
                Maringá - PR
              </CityChip>

              <h5>
                Matriz
              </h5>

              <p className="address">
                Endereço: Av. Duque de Caxias, 882
                Ed. New Tower Plaza – Torre II SL 1201
                Bairro: Zona 01 – CEP: 87013-180
              </p>

              <Link to={"https://maps.app.goo.gl/LdwnvFwRmxbheyad7"} target="_blank">Ver no mapa <span><ArrowForwardIcon /></span></Link>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CityChip className="chip-mb">
                Fortaleza - CE
              </CityChip>

              <h5>Filia Fortaleza</h5>

              <p className="address">
                Endereço: Av. Desembargador
                Moreira, 1701 Bairro: Aldeota
                CEP: 60170-001
              </p>

              <Link to={"https://maps.app.goo.gl/Vpoq1KVVMBWXPFSw8"} target="_blank">Ver no mapa <span><ArrowForwardIcon /></span></Link>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CityChip>
                Curitiba - PR
              </CityChip>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <h5>
                CEBP
              </h5>

              <p className="address">
                Endereço: Rua Alfredo Bufren, 155
                Ed. Centro Empresarial Bellinati Perez
                Bairro: Centro – CEP: 80020-240
              </p>

              <Link to={"https://maps.app.goo.gl/HEyNegX7gSnS1J5N8"} target="_blank">Ver no mapa <span><ArrowForwardIcon /></span></Link>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <h5>
                Filial Park & Business
              </h5>

              <p className="address">
                Endereço: Av. Marechal Deodoro, 502B
                Ed. Marechal Deodoro
                Bairro: Centro – CEP: 80010-010
              </p>

              <Link to={"https://maps.app.goo.gl/AyVEVWMqUD6BAQXB8"} target="_blank">Ver no mapa <span><ArrowForwardIcon /></span></Link>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <h5>
                Filial Marechal
              </h5>

              <p className="address">
                Endereço: Av. Marechal Floriano Peixoto,
                306 Ed. Mon. Exec. Center – 14º Andar –
                Sala 144 Bairro: Centro – CEP: 80010-130
              </p>

              <Link to={"https://maps.app.goo.gl/G9UM6hiymK1XqL7A7"} target="_blank">Ver no mapa <span><ArrowForwardIcon /></span></Link>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <h5>
                Filial Toronto
              </h5>

              <p className="address">
                Endereço: Av. Marechal Floriano Peixoto,
                672 Ed. Toronto
                Bairro: Parolin – CEP: 80320-110
              </p>

              <Link className="last-map-link" to={"https://maps.app.goo.gl/RAuEs4UTuq8hHfBXA"} target="_blank">Ver no mapa <span><ArrowForwardIcon /></span></Link>
            </Grid>

          </Grid>
        </BuildingsAddresses>

        <BuildingsImageContainer item xs={12} sm={12} md={12} lg={5}>
          <img src={BuildingBp} alt="Imagem de prédios" />
        </BuildingsImageContainer>

      </BpBuildingsAddressesContainer>

    </>
  );
}

export default About;