import styled from "styled-components";
import { theme } from "../../config/Theme";

export const SectionValidacaoBoleto = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const ParagraphDescription = styled.p`
  margin-bottom: 1rem;
`;

export const FormValidacaoBoleto = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 5rem 0;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;
`;

export const InputValidacaoBoleto = styled.input`
  border-radius: 8px;
  border: 1px solid ${theme.colors.grey.lightActive};
  padding: .8rem;
  outline: none;
  width: 100%;
  background-color: ${theme.colors.white} !important;
  
  font-family: ${theme.fonts.PoppinsRegular};
  color: currentColor;
  
  &:focus {
    border: 1px solid ${theme.colors.primary.normal};
  }
  &:hover {
    border: 1px solid ${theme.colors.primary.normal};
  }

  transition: all .3s;
`;

export const LegendaInput = styled.legend`
  font-family: ${theme.fonts.RalewayRegular};
  padding: 0;
`;

export const WarningText = styled.span`
  max-height: 0;
  color: ${theme.colors.primary.dark};
  font-family: ${theme.fonts.RalewayRegular};
  overflow: hidden;

  &.visible {
    max-height: 1.5rem;
  }
  
  transition: all .3s;
`;

export const SubmitButton = styled.button`
  height: 3rem;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: ${theme.colors.primary.normal};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  color: ${theme.colors.white};
  cursor: pointer;
  
  &:hover {
    background-color: ${theme.colors.primary.normalHover};
  }

  &:disabled {
    background-color: #B2B2B2 !important;
    cursor: default;
  }

  transition: all .15s;
`;

export const ModalValidacaoBoleto = styled.dialog`
  & > * {
    font-family: ${theme.fonts.RalewayRegular};
  }
  z-index: 999;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .5);
  backdrop-filter: blur(5px);
  height: 100%;
  width: 100%;
  border: none;
  outline: none;

  opacity: 0;
  pointer-events: none;
  
  &[open] {
    opacity: 1;
    pointer-events: auto;
  }

  transition: all .15s;
`;

export const ModalBox = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  width: 29.5rem;
  margin: 1rem;
  background-color: ${theme.colors.white};
  border-radius: 8px;
  gap: 1rem;
  align-items: center;
`;

export const ModalBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 100%;

  img {
    height: 3.75rem;
    width: 3.75rem;
  }
`;

export const ModalBoxTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .2rem;
  text-align: center;

  h4 {
    font-weight: 700;
    font-family: ${theme.fonts.RalewayBold};
    font-size: 1.25rem;
  }

  p {
    font-size: 1rem;
  }
`;

export const ModalButton = styled.button`
  height: 3rem;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: ${theme.colors.primary.normal};
  width: 100%;

  font-family: ${theme.fonts.RalewayBold};
  
  &:hover {
    background-color: ${theme.colors.primary.normalHover};
  }

  color: ${theme.colors.white};
  cursor: pointer;
  transition: all .15s;
`;