export const addBodyClassLockScroll = (body: HTMLElement) => {
	const scrollY = window.scrollY;
	body.classList.add("lock-scroll");
	body.style.top = `-${scrollY}px`;
}

export const removeBodyClassLockScroll = (body: HTMLElement) => {
	const scrollY = parseInt(body.style.top || '0') * -1;
	body.classList.remove("lock-scroll");
	body.style.top = '';
	window.scrollTo({top: scrollY, behavior: "auto"});
}

export const toggleModalById = (elementId: string, body: HTMLElement, toShow: boolean) => {
	const element = document.getElementById(elementId) as HTMLDialogElement

	// const options: BodyScrollOptions = {
	// 	reserveScrollBarGap: true,
	//   };

	if (toShow) {
		// disableBodyScroll(body, options)
		addBodyClassLockScroll(body)
		element.show()
	}
	else {
		// enableBodyScroll(body)
		removeBodyClassLockScroll(body)
		element.close()
	}
}