import React, { FunctionComponent, useEffect, useState } from 'react';
import { Ul, BurgerUl, DropdownLink } from './styles';
import { useWindowSize } from 'react-use';
import { Link } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface RightNavProps {
  openMenu: boolean;
  setOpenMenu: (openMenu: boolean) => void;
}

const RightNav: FunctionComponent<RightNavProps> = ({ openMenu, setOpenMenu }) => {
  const { width: screenWidth } = useWindowSize();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleNavClick = ({ currentTarget }: any) => {
    document.querySelectorAll(".active-page").forEach(e => e.classList.remove('active-page'))
    currentTarget.className = "active-page";
  }
  const ddHandleNavClick = ({ currentTarget }: any) => {
    document.querySelectorAll(".active-page").forEach(e => e.classList.remove('active-page'))
    document.querySelectorAll(".dd-contato").forEach(e => e.classList.add('active-page'));
  }

  return (
    <>
      {
        screenWidth >= 1050 ?
          (
            <Ul>
              <li><Link to={"/sobre"} onClick={handleNavClick}>A Bellinati Perez</Link></li>
              <li><Link to={"/responsabilidade-social"} onClick={handleNavClick}>Responsabilidade social</Link></li>
              <li><Link to={"/nossas-solucoes"} onClick={handleNavClick}>Nossas Soluções</Link></li>
              <li><Link to={"/carreira"} onClick={handleNavClick}>Carreira</Link></li>
              {/* <li><Link to={"/validar-boleto"} onClick={handleNavClick}>Validar boleto</Link></li> */}
              <li className="dd-contato">
                <DropdownLink
                  dropdownopen={dropdownOpen}
                  onMouseOver={() => setDropdownOpen(true)}
                  onMouseOut={() => setDropdownOpen(false)}
                >
                  <span>Contato</span>
                  <div id="myDropdown" className="dropdown-content">
                    <Link to={"/fale-com-a-gente"} onClick={ddHandleNavClick}>Fale com a gente</Link>
                    <Link to={"/ouvidoria"} onClick={ddHandleNavClick}>Ouvidoria</Link>
                  </div>
                </DropdownLink>
              </li>
            </Ul>
          )
          :
          screenWidth >= 900 ?
            (
              <>
                <Ul>
                  <li><Link to={"/sobre"}>A Bellinati Perez</Link></li>
                  <li><Link to={"/responsabilidade-social"}>Responsabilidade social</Link></li>
                  <li><Link to={"/nossas-solucoes"}>Nossas Soluções</Link></li>
                </Ul>
                <BurgerUl openMenu={openMenu}>
                  <li onClick={() => setOpenMenu(!openMenu)}><Link to={"/sobre"}>A Bellinati Perez</Link></li>
                  <li onClick={() => setOpenMenu(!openMenu)}><Link to={"/responsabilidade-social"}>Responsabilidade social</Link></li>
                  <li onClick={() => setOpenMenu(!openMenu)}><Link to={"/nossas-solucoes"}>Nossas Soluções</Link></li>
                  <li onClick={() => setOpenMenu(!openMenu)}><Link to={"/carreira"}>Carreira</Link></li>
                  <li onClick={() => setOpenMenu(!openMenu)}><Link to={"/fale-com-a-gente"}>Fale com a gente</Link></li>
                  <li onClick={() => setOpenMenu(!openMenu)}><Link to={"/ouvidoria"}>Ouvidoria</Link></li>
                  {/* <li onClick={() => setOpenMenu(!openMenu)}><Link to={"/validar-boleto"}>Validar boleto</Link></li> */}
                </BurgerUl>
              </>
            )
            :
            (
              <BurgerUl openMenu={openMenu}>
                <li onClick={() => setOpenMenu(!openMenu)}><Link to={"/sobre"}>A Bellinati Perez</Link></li>
                <li onClick={() => setOpenMenu(!openMenu)}><Link to={"/responsabilidade-social"}>Responsabilidade social</Link></li>
                <li onClick={() => setOpenMenu(!openMenu)}><Link to={"/nossas-solucoes"}>Nossas Soluções</Link></li>
                <li onClick={() => setOpenMenu(!openMenu)}><Link to={"/carreira"}>Carreira</Link></li>
                <li onClick={() => setOpenMenu(!openMenu)}><Link to={"/fale-com-a-gente"}>Fale com a gente</Link></li>
                <li onClick={() => setOpenMenu(!openMenu)}><Link to={"/ouvidoria"}>Ouvidoria</Link></li>
                {/* <li onClick={() => setOpenMenu(!openMenu)}><Link to={"/validar-boleto"}>Validar boleto</Link></li> */}
              </BurgerUl>
            )
      }
    </>
  );
}

export default RightNav;